// Configurable variables
const minInterval = 2000; // Minimum time between appearances (in milliseconds)
const maxInterval = 4500; // Maximum time between appearances (in milliseconds)
const maxNumberOfHearts = 10; // Maximum number of hearts ( + 1)
const heartEmoji = "🍄"; // The emoji to display

function createHearts() {
  // Determine the random number of hearts (between 2 and maxNumberOfHearts)
  const numberOfHearts = Math.floor(Math.random() * maxNumberOfHearts) + 2;

  // Set random position relative to the visible screen (viewport) and consider the scroll position
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const scrollX = window.scrollX;
  const scrollY = window.scrollY;

  const x = Math.random() * screenWidth + scrollX; // Ensuring the position includes the scroll offset
  const y = Math.random() * screenHeight + scrollY; // Ensure hearts are placed within the visible screen

  // Create the hearts and append them to the body
  for (let i = 0; i < numberOfHearts; i++) {
    const heart = document.createElement("div");
    heart.className = "heart";
    heart.innerText = heartEmoji;

    // Apply random small size (between 0.5x and 1x)
    const size = Math.random() * 0.5 + 0.5;
    heart.style.fontSize = `${size}rem`;

    // Apply the same position for all hearts but slightly adjust to create variation
    heart.style.position = "absolute";
    heart.style.left = `${x}px`;
    heart.style.top = `${y}px`;

    // Random rotation and direction for a 360° spread
    const directionAngle = Math.random() * 360; // Random angle in degrees
    const rotationAngle = Math.random() * 360; // Random rotation for the heart itself
    const distance = Math.random() * 100 + 50; // Randomized travel distance

    // Rotate heart to match its movement direction
    heart.style.transform = `rotate(${rotationAngle}deg)`;
    heart.style.opacity = "1";
    heart.style.transition = "all 4s ease";

    document.body.appendChild(heart);

    // Move the heart in a random direction (like spreading out from a raindrop)
    setTimeout(() => {
      const radians = directionAngle * (Math.PI / 180); // Convert angle to radians for movement
      const dx = Math.cos(radians) * distance;
      const dy = Math.sin(radians) * distance;

      // Ensure the hearts don't move outside the screen boundaries
      let finalX = x + dx;
      let finalY = y + dy;

      // Adjust final positions to stay within screen bounds
      finalX = Math.min(Math.max(finalX, scrollX), screenWidth + scrollX - 10); // Boundaries check
      finalY = Math.min(Math.max(finalY, scrollY), screenHeight + scrollY - 10); // Boundaries check

      heart.style.transform = `translate(${finalX - x}px, ${
        finalY - y
      }px) rotate(${rotationAngle + 45}deg)`;
      heart.style.opacity = "0";
    }, 100);

    // Remove the heart after it fades out (stays on screen for 4 seconds)
    setTimeout(() => {
      heart.remove();
    }, 4000); // Fades out over 4 seconds
  }
}

// Randomize the interval between heart creations
function randomHeartAppearance() {
  const interval = Math.random() * (maxInterval - minInterval) + minInterval;
  createHearts();
  setTimeout(randomHeartAppearance, interval);
}

// Start the heart appearance loop
randomHeartAppearance();
